import * as Yup from 'yup';

import { EAvailableCurrency } from '@shared/constants/siteInfo';
import { getNumberRegexWithDecimals } from '@shared/utils/regex';
import {
  decimalPlaces,
  mergeSchemas,
  minZero,
  sufficientBalance,
  validBSCAddress,
} from '@shared/utils/schemaValidation';

const AMOUNT_LABEL = 'Deposit amount';
const ADDRESS_LABEL = 'Receiving BSC address';

const AMOUNT_REQUIRED_MESSAGE = `${AMOUNT_LABEL} is required`;
const ADDRESS_REQUIRED_MESSAGE = `${ADDRESS_LABEL} is required`;

export const depositCurrencySchema = Yup.object().shape({
  [EAvailableCurrency.AITECH]: mergeSchemas(
    Yup.string(),
    decimalPlaces(AMOUNT_LABEL, 3),
    minZero(AMOUNT_LABEL),
    sufficientBalance
  )
    .typeError(AMOUNT_REQUIRED_MESSAGE)
    .matches(getNumberRegexWithDecimals(3), 'Please enter a valid number')
    .required(AMOUNT_REQUIRED_MESSAGE),
  address: mergeSchemas(Yup.string(), validBSCAddress).required(
    ADDRESS_REQUIRED_MESSAGE
  ),
});

export type DepositCurrencyFormData = Yup.InferType<
  typeof depositCurrencySchema
>;

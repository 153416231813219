import * as Yup from 'yup';

import { SUPPORTED_IMAGE_FORMATS } from '@shared/constants/files';

export const imageFormSchema = Yup.object({
  files: Yup.mixed<FileList>()
    .test('fileType', 'Invalid file format', (value) => {
      if (!value) return true;
      const file = value[0];
      if (!file) return true;

      return SUPPORTED_IMAGE_FORMATS.includes(file.type);
    })
    .required('Please select a file'),
});

export type ImageFormData = {
  files: FileList;
};

import { createContext, useContext } from 'react';

import { DepositModalContextType } from './types';

const DepositModalContext = createContext<DepositModalContextType>({
  triggerModal: () => {},
  triggerSuccessModal: () => {},
});

const useDepositModal = () => useContext(DepositModalContext);

export { DepositModalContext, useDepositModal };

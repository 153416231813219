export enum RateMode {
  PERCENTAGE,
  RAW,
}

function addDenominator(amount: number) {
  return +(amount * 10_000).toFixed(2);
}

function removeDenominator(amount: number) {
  return Number(amount) / 10_000;
}

function convertToPercentageRate(rate: number) {
  const raw = removeDenominator(rate);
  const percentageRate = raw * 100;

  return parseFloat(percentageRate.toFixed(2));
}

function convertToRawRate(rate: number) {
  const rawPercentage = rate / 100;

  return addDenominator(rawPercentage);
}

function convertRate(rate: number, mode: RateMode) {
  switch (mode) {
    case RateMode.PERCENTAGE:
      return convertToPercentageRate(rate);
    case RateMode.RAW:
      return convertToRawRate(rate);
  }
}

function calculateTotal(amount?: number, rate?: number) {
  if (!amount || !rate) return 0;

  return amount - amount * (rate / 100);
}

export { addDenominator, removeDenominator, convertRate, calculateTotal };

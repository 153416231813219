import { isAddress } from 'viem';
import * as Yup from 'yup';

import { sanitizeInput } from '@components/form/hookFormInput/sanitizer';

import { formatDecimals } from '@features/utils/numbers';

const decimalPlaces = (label: string, allowed = 3) =>
  Yup.string()
    .typeError(`${label} is required`)
    .test(
      'max-decimals',
      `Amount can have at most ${allowed} decimal places`,
      (value) => {
        if (value !== undefined) {
          const [, decimalPart] = sanitizeInput(value.toString()).split('.');

          if (!decimalPart) {
            return true;
          }

          return decimalPart.length <= allowed;
        }
        return true;
      }
    );

const sufficientBalance = Yup.string().test(
  'sufficient-balance',
  'Your balance is insufficient',
  function (value) {
    if (!value) return true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { accountBalance }: any = this.options.context;
    const accountBalanceDecimals =
      formatDecimals(accountBalance, 9)?.toString() || 0;

    return +value <= +accountBalanceDecimals;
  }
);

const minZero = (label: string) =>
  Yup.string().test(
    'min-zero',
    `${label} must be greater than 0`,
    function (value) {
      if (value === undefined) return true;

      return +value > 0;
    }
  );

const validBSCAddress = Yup.string()
  .typeError('Receiving BSC address is required')
  .test(
    'valid-ether-address',
    'Please enter valid BSC wallet address',
    (value) => {
      if (!value) return true;

      return isAddress(value);
    }
  );

function mergeSchemas<T extends Yup.Schema>(
  schemaType: T,
  ...validations: T[]
) {
  return validations.reduce((result, validation) => {
    return result.concat(validation);
  }, schemaType);
}

export {
  decimalPlaces,
  minZero,
  sufficientBalance,
  validBSCAddress,
  mergeSchemas,
};

'use client';

import { useState } from 'react';

import DepositFundsModal from '../variants/DepositFundsModal';
import DepositSuccessModal from '../variants/DepositSuccessModal';
import { DepositModalContext } from './context';
import { DepositSuccessModalState } from './types';

export interface Props {
  children: React.ReactNode;
}

export const DepositModalProvider = ({ children }: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [successState, setSuccessState] =
    useState<DepositSuccessModalState | null>();

  const triggerModal = () => {
    setOpenModal(true);
  };

  const triggerSuccessModal = (state: DepositSuccessModalState) => {
    setOpenModal(false);
    setSuccessState(state);
  };

  return (
    <DepositModalContext.Provider value={{ triggerModal, triggerSuccessModal }}>
      {children}
      {openModal ? (
        <DepositFundsModal
          isModalOpen={openModal}
          setIsModalOpen={setOpenModal}
          onSuccess={triggerSuccessModal}
        />
      ) : null}
      {successState?.depositAmount ? (
        <DepositSuccessModal
          depositAmount={successState.depositAmount}
          onClose={() => setSuccessState(null)}
        />
      ) : null}
    </DepositModalContext.Provider>
  );
};

import { IDropdownOption } from '@interfaces';
import {
  GpuResource,
  IStepProgress,
} from '@interfaces/publisher/productCreation';

export const MARKDOWN_GUIDE_LINK =
  'https://www.markdownguide.org/basic-syntax/';

export const USAGE_GUIDE_LINK =
  'https://docs.google.com/document/d/1dDSrq5v8R_FiU1o9zrOqd_uJuaVkvY-W/edit';

export const datamartPricingModelTypes = [
  { id: 'onetime', value: 'onetime', label: 'One Time Purchase' },
];

export const defaultValuesForBackend = {
  attachments: ['https://example.com'],
};

export const gpuResources: { packageNumber: number; resource: GpuResource }[] =
  [
    {
      packageNumber: 1,
      resource: {
        id: 1,
        instance: 'g4dn.xlarge',
        gpu: '1xNVIDIA T4',
        vCPUs: '4',
        memory: '16GB',
        storage: '1 x 125 NVMe SSD',
        network: '125Gbps',
        ebs: '13.5Gbps',
        price: '0.526',
      },
    },
    {
      packageNumber: 2,
      resource: {
        id: 2,
        instance: 'g4dn.2xlarge',
        gpu: '1xNVIDIA T4',
        vCPUs: '8',
        memory: '32GB',
        storage: '1 x 250 NVMe SSD',
        network: '250Gbps',
        ebs: '7.5Gbps',
        price: '1.052',
      },
    },
    {
      packageNumber: 3,
      resource: {
        id: 3,
        instance: 'p3.2xlarge',
        gpu: '1xNVIDIA V100',
        vCPUs: '8',
        memory: '61GB',
        storage: '1 x 500 NVMe SSD',
        network: '10Gbps',
        ebs: '7.5Gbps',
        price: '3.060',
      },
    },
  ];

export const PRE_DEPLOYED_SERVICE_OPTIONS: IDropdownOption[] = [
  {
    value: 'Service 1: Audio Generation - AudioCraft',
    id: 'audio_generation',
  },
  {
    value: 'Service 2: Photo Generation - Stable Diffusion',
    id: 'photo_generation_stable_diffusion',
  },
  {
    value: 'Service 3: Photo Generation - Open Journey',
    id: 'photo_generation_open_journey',
  },
  { value: 'Service 4: LLM - Mistral', id: 'llm_mistral' },
  { value: 'Service 5: LLM - Smaug', id: 'llm_smaug' },
];

export const DEFAULT_VERSION_NAME = 'Unnamed';

export const steps: IStepProgress[] = [
  { id: 1, label: 'About' },
  { id: 2, label: 'Usage' },
  { id: 3, label: 'Pricing' },
  { id: 4, label: 'Resources' },
];

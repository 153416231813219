import usePolling from '@shared/hooks/usePolling';

import useConfirmListener from './useConfirmListener';

export interface IConfirmListener {
  onPoll: (stopPolling: () => void) => void;
  confirmations?: number;
}

const useConfirmListenerWithPolling = ({
  onPoll,
  confirmations = 7,
}: IConfirmListener) => {
  const { startPolling } = usePolling(onPoll);

  const { triggerListener } = useConfirmListener({
    onConfirm: () => {
      startPolling();
    },
    confirmations,
  });

  return { triggerListener };
};

export default useConfirmListenerWithPolling;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { useAccount, useSwitchChain } from 'wagmi';

import config from '@shared/config';

const useWithCorrectNetwork = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: (arg: any) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (error: any) => void
) => {
  const { chainId } = useAccount();
  const { switchChain } = useSwitchChain();
  const isCorrectChain = +config.chainId === chainId;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (arg?: any) => {
    if (!isCorrectChain) {
      return switchChain(
        { chainId: +config.chainId },
        {
          onSuccess: () => fn(arg),
          onError,
        }
      );
    }

    return fn(arg);
  };
};

export default useWithCorrectNetwork;

import Modal from '@components/modals/Modal';
import ModalFooter from '@components/modals/ModalFooter';

import { Party } from '@shared/assets';

interface IProps {
  depositAmount: number;
  onClose: () => void;
}

const DepositSuccessModal = ({ depositAmount, onClose }: IProps) => {
  return (
    <Modal isOpen={true} hideCloseIcon className="!max-w-25.5rem">
      <div className="space-y-6">
        <div className="flexCenter roundedBlTr size-20 border border-neutral700 bg-neutral900">
          <Party className="size-7 text-green500" />
        </div>
        <p className="font-xxlarge">
          You successfully deposited{' '}
          <span className="text-green500">{depositAmount} AITECH</span>
        </p>
      </div>
      <ModalFooter
        confirmButtonProps={{
          text: 'Continue',
          onClick: onClose,
        }}
      />
    </Modal>
  );
};

export default DepositSuccessModal;

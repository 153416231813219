import { useCallback } from 'react';

import ISMCPayment from '@web3/contracts/SMCPayment/abi/ISMCPayment.json';
import { useMinedTx, useWithCorrectNetwork } from '@web3/hooks';
import { MutationArgs } from '@web3/types';

import config from '@shared/config';

export default function useUpdateBurnRate(mutationArgs: MutationArgs = {}) {
  const { sendTransaction, isLoading: isApproveLoading } =
    useMinedTx(mutationArgs);

  const updateBurnRate = useCallback(
    async (burnRate: number) => {
      return await sendTransaction({
        abi: ISMCPayment,
        address: config.smcPaymentAddress,
        functionName: 'setBurnRate',
        args: [burnRate],
      });
    },
    [sendTransaction]
  );

  const submitWithCorrectNetwork = useWithCorrectNetwork(updateBurnRate);

  return {
    updateBurnRate: submitWithCorrectNetwork,
    isUpdatingBurnRate: isApproveLoading,
  };
}

import { usePaymentDeposit } from '@web3/contracts/SMCPayment/hooks';

import { ClockIconDeposit } from '@shared/assets';
import config from '@shared/config';
import { toastInfo } from '@shared/utils';

import useDepositPolling from './useDepositPolling';

export interface IDepositFunds {
  onSuccess: () => void;
  onError?: () => void;
  onClose?: () => void;
}

export default function useDepositFunds(
  currentDeposit: number,
  { onSuccess, onError, onClose }: IDepositFunds
) {
  const pollDeposit = useDepositPolling(currentDeposit, { onSuccess });

  const { deposit, isLoading: isDepositLoading } = usePaymentDeposit(
    config.smcPaymentAddress,
    {
      onSuccess: () => {
        onClose?.();

        toastInfo(
          'Deposit is being processed. It may take a while depending on the network speed. Please wait patiently.',
          {
            disabledClose: true,

            Icon: <ClockIconDeposit className="text-turquoise500" />,
            id: 'deposit-funds',
            autoClose: false,
          }
        );
      },
      onPoll: pollDeposit,
      onError,
    }
  );

  return { deposit, isDepositLoading };
}

import { useMemo } from 'react';

import { Address } from 'viem';
import { useAccount, useReadContract } from 'wagmi';

import { ZERO_BI } from '@shared/constants/numbers';

import IERC20 from '../abi/IERC20.json';

export default function useERC20Allowance(
  contractAddress: Address,
  operator: string
) {
  const { address } = useAccount();

  const { data: allowance, isLoading } = useReadContract({
    abi: IERC20,
    address: contractAddress,
    functionName: 'allowance',
    args: [address, operator],
  });

  return useMemo(() => {
    let result = allowance;

    if (!result) {
      result = ZERO_BI;
    }

    return {
      allowance: allowance as bigint,
      isLoading,
    };
  }, [allowance, isLoading]);
}

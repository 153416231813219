import { useCallback } from 'react';

import { waitForTransactionReceipt } from '@wagmi/core';
import { useChainId } from 'wagmi';

import { HexPrefixValue } from '@web3/types';

import { wagmiConfig } from '@shared/config/wagmiConfig';

export interface IConfirmListener {
  onConfirm?: () => void;
  confirmations?: number;
}

const useConfirmListener = ({
  onConfirm,
  confirmations = 7,
}: IConfirmListener) => {
  const chainId = useChainId();

  const triggerListener = useCallback(
    async (hash: HexPrefixValue) => {
      const receipt = await waitForTransactionReceipt(wagmiConfig, {
        chainId,
        hash,
        confirmations,
        pollingInterval: 3_000,
      });

      if (receipt.status === 'reverted') {
        return;
      }

      onConfirm?.();
    },
    [onConfirm, confirmations, chainId]
  );

  return {
    triggerListener,
  };
};

export default useConfirmListener;

import { useMemo } from 'react';

import { Address, zeroAddress } from 'viem';
import { useAccount, useBalance } from 'wagmi';

import { useERC20Balance } from '@web3/contracts/ERC20/hooks';
import { isZeroAddress, toEther } from '@web3/helpers';

import { ZERO_BI } from '@shared/constants/numbers';

export default function useWalletBalance(
  currencyAddress: Address = zeroAddress
) {
  const { address } = useAccount();

  const { data: nativeBalance } = useBalance({
    address,
    query: {
      enabled: isZeroAddress(currencyAddress),
    },
  });

  const { data: erc20Balance } = useERC20Balance(currencyAddress, {
    enabled: !isZeroAddress(currencyAddress),
  });

  return useMemo(() => {
    let balance: bigint | undefined;

    if (isZeroAddress(currencyAddress)) {
      balance = nativeBalance?.value;
    } else {
      balance = erc20Balance as bigint;
    }

    const raw = balance || ZERO_BI;

    return {
      rounded: toEther(raw),
      raw,
    };
  }, [currencyAddress, nativeBalance, erc20Balance]);
}

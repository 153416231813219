import { RefundRequestStatus } from '@shared/enums/refunds';

export const REVIEWING_STATUSES = [
  RefundRequestStatus.PUBLISHER_REVIEWING,
  RefundRequestStatus.ADMIN_REVIEWING,
].toString();

export const APPROVED_STATUSES = [
  RefundRequestStatus.PUBLISHER_APPROVED,
  RefundRequestStatus.ADMIN_APPROVED,
].toString();

export const REJECTED_STATUSES = [
  RefundRequestStatus.PUBLISHER_REJECTED,
  RefundRequestStatus.ADMIN_REJECTED,
  RefundRequestStatus.REJECTED,
].toString();

export const DEFAULT_REFUND_STATUS = [
  REVIEWING_STATUSES,
  APPROVED_STATUSES,
  REJECTED_STATUSES,
];

export const REFUND_STATUS_MAP = {
  [DEFAULT_REFUND_STATUS.toString()]: 'ALL',
  [REVIEWING_STATUSES]: 'In review',
  [REJECTED_STATUSES]: 'Rejected',
  [APPROVED_STATUSES]: 'Approved',
};

export const RefundRequestStatusMessage = {
  [RefundRequestStatus.PUBLISHER_REVIEWING]: 'Publisher reviewing',
  [RefundRequestStatus.ADMIN_REVIEWING]: 'Admin reviewing',
  [RefundRequestStatus.PUBLISHER_APPROVED]: 'Publisher approved',
  [RefundRequestStatus.PUBLISHER_REJECTED]: 'Publisher rejected',
  [RefundRequestStatus.REJECTED]: 'Publisher rejected',
  [RefundRequestStatus.ADMIN_APPROVED]: 'Admin approved',
  [RefundRequestStatus.ADMIN_REJECTED]: 'Admin rejected',
};

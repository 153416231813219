import { ConversionData, ExchangeRates } from '@types';

import { CryptoCurrencyID } from '@shared/constants/currencies';

const buildExchangeRates = (aitechPrice: number): ExchangeRates => {
  const exchangeRates: ExchangeRates = {};

  exchangeRates[CryptoCurrencyID.AITECH] = {
    [CryptoCurrencyID.USDAI]: aitechPrice,
  };

  exchangeRates[CryptoCurrencyID.USDAI] = {
    [CryptoCurrencyID.AITECH]: 1 / aitechPrice,
  };

  return exchangeRates;
};

const convertCurrency = ({
  amount,
  rates,
  initialCoin,
  targetCoin,
}: ConversionData): number => {
  const rate = rates[initialCoin]?.[targetCoin];

  if (!rate) {
    throw new Error('Unsupported currency conversion');
  }

  return amount * rate;
};

export { convertCurrency, buildExchangeRates };

import { useMemo } from 'react';

import { ConversionData } from '@types';

import { formatDecimals } from '@features/utils/numbers';

import { buildExchangeRates, convertCurrency } from '@shared/utils/currencies';

import useCurrencyRate from './useCurrencyRate';

export default function useCurrencyConverter({
  amount,
  initialCoin,
  targetCoin,
  decimals,
}: Omit<ConversionData, 'rates'> & { decimals?: number }) {
  const { data, isLoading } = useCurrencyRate();

  return useMemo(() => {
    if (isLoading || !data || amount < 0) {
      return 0;
    }

    const rates = buildExchangeRates(+data);

    const result = convertCurrency({
      amount,
      rates,
      initialCoin,
      targetCoin,
    });

    return formatDecimals(result, decimals);
  }, [amount, data, initialCoin, targetCoin, isLoading, decimals]);
}

import { useCallback, useEffect, useRef } from 'react';

export default function usePolling(
  pollingFn: (stopPolling: () => void) => void,
  pollInterval: number = 4000
) {
  const intervalIdRef = useRef<NodeJS.Timeout>();

  const stopPollingRef = useRef(() => {});

  const startPolling = useCallback(() => {
    const stopPolling = () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };

    intervalIdRef.current = setInterval(() => {
      pollingFn(stopPolling);
    }, pollInterval);

    stopPollingRef.current = stopPolling;

    pollingFn(stopPolling);

    return stopPolling;
  }, [pollingFn, pollInterval]);

  useEffect(() => {
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  return {
    startPolling,
  };
}

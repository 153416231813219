import { useEffect } from 'react';

import { useAccount, useDisconnect } from 'wagmi';

import useCurrentUser from '@api/user/auth/queries/useCurrentUser';

import { toastWarning } from '@shared/utils';

const useCorrectWalletConnection = () => {
  const { data: user } = useCurrentUser();
  const { disconnect } = useDisconnect();

  const { address } = useAccount();

  useEffect(() => {
    if (
      address &&
      user?.data.cryptoWalletAddress &&
      user?.data.cryptoWalletAddress?.toLowerCase() !== address?.toLowerCase()
    ) {
      disconnect();
      toastWarning('Different wallet connected', {
        description: `Please connect with your address ${user?.data.cryptoWalletAddress} to proceed`,
      });
    }
  }, [address, user?.data.cryptoWalletAddress, disconnect]);
};

export default useCorrectWalletConnection;

import { Address, zeroAddress } from 'viem';
import { useAccount, useReadContract } from 'wagmi';

import config from '@shared/config';
import { wagmiConfig } from '@shared/config/wagmiConfig';

import IERC20 from '../abi/IERC20.json';

export default function useERC20Balance(
  contractAddress: Address = zeroAddress,
  { enabled }: { enabled: boolean }
) {
  const { address } = useAccount({ config: wagmiConfig });

  return useReadContract({
    address: contractAddress,
    abi: IERC20,
    chainId: config.chainId,
    functionName: 'balanceOf',
    args: [address],
    query: {
      enabled,
    },
  });
}

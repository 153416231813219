import { useCallback } from 'react';

import { Address } from 'viem';

import { useMinedTx } from '@web3/hooks';
import { MutationArgs } from '@web3/types';

import IERC20 from '../abi/IERC20.json';
import useERC20Allowance from './useERC20Allowance';

export default function useERC20Approval(
  tokenAddress: Address,
  operator: string,
  mutationArgs: MutationArgs = {}
) {
  const { allowance, isLoading: isAllowanceLoading } = useERC20Allowance(
    tokenAddress,
    operator
  );

  const { sendTransaction, isLoading: isApproveLoading } =
    useMinedTx(mutationArgs);

  const approve = useCallback(
    async (weiAmount: bigint) => {
      return await sendTransaction({
        abi: IERC20,
        address: tokenAddress,
        functionName: 'approve',
        args: [operator, weiAmount],
      });
    },
    [sendTransaction, operator, tokenAddress]
  );

  const approveWithCheck = useCallback(
    async (requiredAmount: bigint) => {
      const allowanceBN = allowance as bigint;

      if (!allowance || requiredAmount > allowanceBN) {
        const isApproveSuccess = await approve(requiredAmount);

        if (!isApproveSuccess) {
          return false;
        }
      }
      return true;
    },
    [allowance, approve]
  );

  return {
    allowance,
    approve: approveWithCheck,
    isLoading: isAllowanceLoading || isApproveLoading,
  };
}

import { RefObject, useEffect, useState } from 'react';

const useTooltip = (
  triggerRef: RefObject<HTMLDivElement>,
  offsetX: number = 0,
  offsetY: number = 0
) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<{
    top: string;
    left: string;
  }>({ top: '0px', left: '0px' });

  useEffect(() => {
    const handleEvent = (show: boolean) => () => setShowTooltip(show);

    const triggerElement = triggerRef.current;
    if (triggerElement) {
      triggerElement.addEventListener('mouseenter', handleEvent(true));
      triggerElement.addEventListener('mouseleave', handleEvent(false));
      triggerElement.addEventListener('focus', handleEvent(true));
      triggerElement.addEventListener('blur', handleEvent(false));

      return () => {
        triggerElement.removeEventListener('mouseenter', handleEvent(true));
        triggerElement.removeEventListener('mouseleave', handleEvent(false));
        triggerElement.removeEventListener('focus', handleEvent(true));
        triggerElement.removeEventListener('blur', handleEvent(false));
      };
    }
  }, [triggerRef]);

  useEffect(() => {
    if (showTooltip && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: `${rect.top + window.scrollY + offsetY}px`,
        left: `${rect.left + window.scrollX + offsetX}px`,
      });
    }
  }, [showTooltip, triggerRef, offsetX, offsetY]);

  return { showTooltip, tooltipPosition };
};

export default useTooltip;

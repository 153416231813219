interface IProps {
  currency: string;
}

const CurrencyLabel = ({ currency }: IProps) => {
  return (
    <div className="flexCenter ml-auto bg-neutral700 px-1.5 py-1">
      <p className="font-xsmall font-bold leading-none text-neutral200">
        {currency}
      </p>
    </div>
  );
};

export default CurrencyLabel;

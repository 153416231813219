import { useQuery } from '@tanstack/react-query';

import { CURRENCY_RATE } from '@shared/constants/queryKeys';

import { ratesApi } from '../ratesApi';

const useCurrencyRate = () => {
  return useQuery({
    queryKey: [CURRENCY_RATE],
    queryFn: async () => {
      const { data } = await ratesApi.client.getCoinPrice();

      return data;
    },
  });
};

export default useCurrencyRate;

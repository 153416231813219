import { formatEther, parseUnits } from 'viem';

const isZeroAddress = (address: string) => {
  return address === '0x0000000000000000000000000000000000000000';
};

const toWei = (ethers: string | number) => {
  const etherStr = ethers.toString();

  return parseUnits(etherStr, 18);
};

function toEther(wei: bigint) {
  return formatEther(wei);
}

export { isZeroAddress, toWei, toEther };

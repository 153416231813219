import { useMemo } from 'react';

import { useReadContract } from 'wagmi';

import config from '@shared/config';
import { RateMode, convertRate } from '@shared/utils/rates';

import ISMCPayment from '../../abi/ISMCPayment.json';

export default function useSystemFeeQuery() {
  const result = useReadContract({
    abi: ISMCPayment,
    address: config.smcPaymentAddress,
    functionName: 'feeRate',
  });

  return useMemo(() => {
    if (!result) return;

    const rawFee = result.data as bigint;

    return convertRate(Number(rawFee), RateMode.PERCENTAGE);
  }, [result]);
}

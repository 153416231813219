import { ReactNode, useRef } from 'react';

import { twMerge } from 'tailwind-merge';

import Portal from '@components/Portal';

import useTooltip from '@shared/hooks/useTooltip';

interface IProps {
  children: ReactNode;
  text: string;
  className?: string;
  tooltipContainerClassName?: string;
  isDisabled?: boolean;
  offsetX?: number;
  offsetY?: number;
}

const Tooltip = ({
  text,
  children,
  className,
  tooltipContainerClassName,
  isDisabled,
  offsetX = 0,
  offsetY = 0,
}: IProps) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const { showTooltip, tooltipPosition } = useTooltip(
    triggerRef,
    offsetX,
    offsetY
  );

  const getTabIndexAndRole = () => {
    if (isDisabled) {
      return {
        role: 'none',
      };
    }

    return {
      tabIndex: 0,
      role: 'button',
    };
  };

  const { tabIndex, role } = getTabIndexAndRole();

  return (
    <div
      className="relative flex items-center"
      tabIndex={tabIndex}
      role={role}
      aria-describedby="tooltip"
      ref={triggerRef}
    >
      {showTooltip && !isDisabled ? (
        <Portal>
          <div
            className={twMerge('absolute z-[10001]', tooltipContainerClassName)}
            style={{ ...tooltipPosition }}
          >
            <div
              id="tooltip"
              role="tooltip"
              className={twMerge(
                'absolute z-[10001] -translate-y-[5rem] transform rounded-lg bg-green500 p-3 text-sm leading-tight text-black shadow-lg transition-all duration-300 ease-in', // bigger z-index because of react-modal overlay
                className
              )}
            >
              {text}
              <div className="absolute bottom-0 left-1/2 h-3 w-3 -translate-x-1/2 translate-y-1/2 rotate-45 transform rounded-sm bg-green500"></div>
            </div>
          </div>
        </Portal>
      ) : null}
      {children}
    </div>
  );
};

export default Tooltip;

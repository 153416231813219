import { useReadContract } from 'wagmi';

import config from '@shared/config';

import ISMCPayment from '../abi/ISMCPayment.json';

export function useSMCPaymentOwnerQuery() {
  return useReadContract({
    abi: ISMCPayment,
    address: config.smcPaymentAddress,
    functionName: 'owner',
  });
}

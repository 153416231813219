import * as Yup from 'yup';

export const threadSchema = Yup.object({
  title: Yup.string().trim().required('Please enter the title of your thread'),
  content: Yup.string()
    .trim()
    .required('Please enter the content of your thread'),
  image: Yup.mixed().notRequired(),
  threadId: Yup.number().notRequired(), // Used when publishing
});

export type ThreadFormData = Yup.InferType<typeof threadSchema>;

import { withClientRequest } from '@api/requestBuilder/client/withClientRequest';
import { withServerRequest } from '@api/requestBuilder/server/withServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import config from '@shared/config';

import { ICoinPriceResponse } from './types';

export const getCoinPrice =
  (request: ICallableRequestBuilder<ICoinPriceResponse>) => async () =>
    request.call(`${config.userApiUrl}/api/v1/token-rates`);

export const ratesApi = {
  client: {
    getCoinPrice: withClientRequest(getCoinPrice),
  },
  server: {
    getCoinPrice: withServerRequest(getCoinPrice),
  },
};

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';

import { useERC20Approval } from '@web3/contracts/ERC20/hooks';
import { toWei } from '@web3/helpers';
import { useWithCorrectNetwork } from '@web3/hooks';

import Modal from '@components/modals/Modal';
import ModalFooter from '@components/modals/ModalFooter';
import { IDefaultModalProps } from '@components/modals/types';
import UserAddressFormInput from '@components/userAddress/UserAddressFormInput';

import config from '@shared/config';
import { PROFILE_KEYS } from '@shared/constants/queryKeys';
import { EAvailableCurrency } from '@shared/constants/siteInfo';
import useWalletBalance from '@shared/hooks/useWalletBalance';
import {
  DepositCurrencyFormData,
  depositCurrencySchema,
} from '@shared/schemas';
import { toastError } from '@shared/utils/toast';

import CurrencyInputs from '../currencyInputs';
import useDepositFunds from '../hooks/useDepositFunds';
import { DepositSuccessModalState } from '../provider/types';

interface IProps extends IDefaultModalProps {
  onSuccess: (arg: DepositSuccessModalState) => void;
}

const DepositFundsModal = ({
  isModalOpen,
  setIsModalOpen,
  onSuccess,
}: IProps) => {
  const queryClient = useQueryClient();

  const accountBalance = useWalletBalance(config.aitechTokenAddress);

  const methods = useForm<DepositCurrencyFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(depositCurrencySchema),
    context: { accountBalance: accountBalance.rounded },
  });

  const {
    handleSubmit,
    formState: { isValid, errors },
    watch,
    register,
  } = methods;

  const aitech = watch(EAvailableCurrency.AITECH);

  const { deposit, isDepositLoading } = useDepositFunds(+aitech, {
    onSuccess: () => {
      toast.dismiss('deposit-funds');

      queryClient.invalidateQueries({
        queryKey: PROFILE_KEYS.PURCHASE_INFO,
      });

      onSuccess({ depositAmount: +aitech });
    },
    onClose: () => {
      setIsModalOpen(false);
    },
    onError: () => {
      toastError('Transaction rejected');
    },
  });

  const {
    approve,
    allowance,
    isLoading: isApprovalLoading,
  } = useERC20Approval(config.aitechTokenAddress, config.smcPaymentAddress, {
    onError: () => {
      toastError('Transaction rejected');
    },
  });

  const onSubmit: SubmitHandler<DepositCurrencyFormData> = async (data) => {
    const amountInWei = toWei(data.AITECH.toString());

    await approve(accountBalance.raw);
    await deposit(amountInWei);
  };

  const submitWithCorrectNetwork = useWithCorrectNetwork(onSubmit);

  const isLoading = isApprovalLoading || isDepositLoading;

  return (
    <Modal
      isOpen={isModalOpen}
      disableClose={isLoading}
      setIsOpen={setIsModalOpen}
      modalTitle="Deposit funds"
      modalDescription="Send only AITECH to the system's address."
      className="!max-w-25.5rem"
      modalContentClassName="overflow-visible"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitWithCorrectNetwork)}>
          <div className="space-y-6">
            <UserAddressFormInput
              id="address"
              register={register('address')}
              error={errors.address?.message?.toString()}
            />
            <CurrencyInputs />
          </div>
          <ModalFooter
            confirmButtonProps={{
              text: accountBalance.raw > allowance ? 'Approve' : 'Deposit',
              isDisabled: !isValid || isLoading,
              type: 'submit',
            }}
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

export default DepositFundsModal;

import { useQuery } from '@tanstack/react-query';

import useCurrentUser from '@api/user/auth/queries/useCurrentUser';

import { PROFILE_KEYS } from '@shared/constants/queryKeys';

import { profileApi } from '../profileApi';

export const useGetPurchaseInfo = () => {
  const { data: user } = useCurrentUser();
  return useQuery({
    queryKey: PROFILE_KEYS.PURCHASE_INFO,
    queryFn: () => profileApi.client.getProfilePurchaseInfo(),
    enabled: !!user,
  });
};

import { twMerge } from 'tailwind-merge';

import Tooltip from '@components/tooltip/Tooltip';

import { CopyIcon, FilterCheck } from '@shared/assets';

interface IProps {
  isCopied: boolean;
  copyAddressHandler: () => void;
  className?: string;
  classNameCopy?: string;
  classNameCheck?: string;
}

const CopyIcons = ({
  className,
  classNameCheck,
  classNameCopy,
  copyAddressHandler,
  isCopied,
}: IProps) => {
  const tooltipText = isCopied ? 'Copied' : 'Copy';

  return (
    <Tooltip
      text={tooltipText}
      className="w-max -translate-x-5 -translate-y-18"
    >
      {isCopied ? (
        <FilterCheck
          className={twMerge('text-green500', className, classNameCheck)}
        />
      ) : (
        <CopyIcon
          className={twMerge(
            'cursor-pointer text-white',
            className,
            classNameCopy
          )}
          onClick={copyAddressHandler}
        />
      )}
    </Tooltip>
  );
};

export default CopyIcons;

import { useMutation } from '@tanstack/react-query';
import { waitForTransactionReceipt } from '@wagmi/core';
import { useWriteContract } from 'wagmi';

import { MutationArgs } from '@web3/types';

import { wagmiConfig } from '@shared/config/wagmiConfig';

const useMinedTx = ({ onSuccess, onError }: MutationArgs) => {
  const { writeContractAsync } = useWriteContract({
    config: wagmiConfig,
  });

  const { mutateAsync: sendTransaction, isPending } = useMutation({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: async (...args: any) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const hash = await writeContractAsync(...args);

      const receipt = await waitForTransactionReceipt(wagmiConfig, {
        hash,
        confirmations: 1,
      });

      return receipt.transactionHash;
    },
    onSuccess,
    onError,
  });

  return {
    sendTransaction,
    isLoading: isPending,
  };
};

export default useMinedTx;

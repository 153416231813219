const sanitizeInput = (
  value: string,
  maxNumberOfDecimals?: number,
  maxIntegerLength?: number
) => {
  value = value.replace(/,/g, '.');

  const parts = value.split('.');
  if (parts.length > 2) {
    value = `${parts.shift()}.${parts.join('')}`;
  }

  value = value.replace(/[^\d.]/g, '');

  if (
    maxNumberOfDecimals &&
    parts.length > 1 &&
    parts[1].length > maxNumberOfDecimals
  ) {
    parts[1] = parts[1].substring(0, maxNumberOfDecimals);
    value = parts.join('.');
  }

  if (maxIntegerLength && parts[0].length > maxIntegerLength) {
    parts[0] = parts[0].substring(0, maxIntegerLength);
    value = parts.join('.');
  }

  return value;
};

export { sanitizeInput };

'use client';

import { useEffect } from 'react';
import { UseFormRegisterReturn, useFormContext } from 'react-hook-form';

import { twMerge } from 'tailwind-merge';
import { useAccount } from 'wagmi';

import CopyIcons from '@components/copyIcons';
import HookFormInput from '@components/form/hookFormInput';

import { useCopyText } from '@shared/hooks';

interface IProps {
  id: string;
  className?: string;
  error?: string;
  register: UseFormRegisterReturn<string>;
}

const UserAddressFormInput = ({ id, register, error }: IProps) => {
  const { address } = useAccount();

  const { watch, setValue } = useFormContext();

  useEffect(() => {
    if (!address) {
      return;
    }

    setValue(id, address);
  }, [id, setValue, address]);

  const inputAddress = watch(id) || address;

  const { isCopied, copyAddressHandler } = useCopyText(inputAddress as string);

  return (
    <HookFormInput
      id={id}
      label="Wallet address"
      register={register}
      error={error?.toString()}
      inputClassName={twMerge(
        'peer text-ellipsis px-[12.5px] py-2',
        isCopied ? 'text-green500' : 'text-white'
      )}
      className="mt-4 w-full border-0 p-0 focus-within:border-0"
      required
      autoResize
      innerContainerClassName="flex flex-row justify-between items-center w-full"
      minWidth={400}
      readonly
    >
      <div
        className={twMerge(
          'transitionColor bg-neutral700 p-2',
          error && 'bg-red900 peer-focus:bg-red900'
        )}
      >
        <CopyIcons
          className="size-4"
          isCopied={isCopied}
          copyAddressHandler={copyAddressHandler}
        />
      </div>
    </HookFormInput>
  );
};

export default UserAddressFormInput;

import { useMemo } from 'react';

import useCurrencyConverter from '@api/rates/queries/useCurrencyConverter';

import { CryptoCurrencyID } from '@shared/constants/currencies';

export default function useExchangeRates() {
  const usdai = useCurrencyConverter({
    amount: 1,
    initialCoin: CryptoCurrencyID.AITECH,
    targetCoin: CryptoCurrencyID.USDAI,
  });

  return useMemo(() => {
    return {
      aiTech: 1,
      usdai,
    };
  }, [usdai]);
}

import { useCallback } from 'react';

import { useMinedTx, useWithCorrectNetwork } from '@web3/hooks';
import { MutationArgs } from '@web3/types';

import config from '@shared/config';

import ISMCPayment from '../../abi/ISMCPayment.json';

export default function useUpdateWithdrawFeeRate(
  mutationArgs: MutationArgs = {}
) {
  const { sendTransaction, isLoading: isApproveLoading } =
    useMinedTx(mutationArgs);

  const updateWithdrawFeeRate = useCallback(
    async (feeRate: number) => {
      return await sendTransaction({
        abi: ISMCPayment,
        address: config.smcPaymentAddress,
        functionName: 'setFeeRate',
        args: [feeRate],
      });
    },
    [sendTransaction]
  );

  const submitWithCorrectNetwork = useWithCorrectNetwork(updateWithdrawFeeRate);

  return {
    updateWithdrawFeeRate: submitWithCorrectNetwork,
    isUpdatingWithdrawFeeRate: isApproveLoading,
  };
}

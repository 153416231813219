import { useCallback } from 'react';

import { profileApi } from '@api/user/profile/profileApi';
import { useGetPurchaseInfo } from '@api/user/profile/queries/usePurchaseInfoQuery';

import { formatDecimals, isWithinRange } from '@features/utils/numbers';

import { IDepositFunds } from './useDepositFunds';
import useExchangeRates from './useExchangeRates';

export default function useDepositPolling(
  currentDeposit: number,
  { onSuccess }: IDepositFunds
) {
  const { data: purchaseData } = useGetPurchaseInfo();

  const rates = useExchangeRates();

  const getRequiredBalance = useCallback(() => {
    const oldBalance = purchaseData?.data.balanceAvailable;

    const diffBalance = +currentDeposit * rates!.usdai!;

    const total = +oldBalance! + diffBalance;

    return formatDecimals(total, 2);
  }, [purchaseData, currentDeposit, rates]);

  return useCallback(
    async (stopPolling: () => void) => {
      if (!rates?.usdai) {
        return;
      }

      const response = await profileApi.client.getProfilePurchaseInfo();

      const required = getRequiredBalance();
      const newBalance = formatDecimals(response.data.balanceAvailable, 2)!;

      /* This is because prices on server are approximately 
         calculated within range of few decimal places from UI usdAI rates
      */
      if (!isWithinRange(required, newBalance, 0.04)) {
        return;
      }

      stopPolling();
      onSuccess();
    },
    [rates, onSuccess, getRequiredBalance]
  );
}

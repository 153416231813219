import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { formatDecimals } from '@features/utils/numbers';

import { SwapIcon } from '@shared/assets';
import { EAvailableCurrency } from '@shared/constants/siteInfo';
import { DepositCurrencyFormData } from '@shared/schemas';

import useExchangeRates from '../hooks/useExchangeRates';
import SingleCurrencyInput from './SingleCurrencyInput';

const CurrencyInputs = () => {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext<DepositCurrencyFormData>();

  const rates = useExchangeRates();

  const aitech = watch(EAvailableCurrency.AITECH);

  const amountUSDAI = useMemo(() => {
    if (!aitech || !rates?.usdai) {
      return 0;
    }

    const result = +aitech * rates.usdai;

    return formatDecimals(result, 2);
  }, [rates, aitech]);

  return (
    <>
      <div className="space-y-2">
        <p className="label-small text-neutral200">Enter deposit amount</p>
        <div className="flexCenter flex-col gap-4">
          <SingleCurrencyInput
            currency={EAvailableCurrency.AITECH}
            className="flex w-full"
            errorMessage={errors[
              EAvailableCurrency.AITECH
            ]?.message?.toString()}
            register={register}
            placeholder="0"
            isFormInput
          />
          <SwapIcon className="size-4.5" />
          <SingleCurrencyInput
            currency={EAvailableCurrency.USDAI}
            className="flex w-full"
            register={register}
            value={amountUSDAI}
            inputClassName="cursor-default"
          />
        </div>
      </div>
      <p className="font-medium text-neutral200">
        Current Exchange Rate: {rates?.aiTech} AITECH ≈ {rates?.usdai} USD
      </p>
    </>
  );
};

export default CurrencyInputs;

import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import CurrencyLabel from '@components/currencyLabel';
import HookFormInput from '@components/form/hookFormInput';
import { sanitizeInput } from '@components/form/hookFormInput/sanitizer';
import AutoResizableInput from '@components/inputs/AutoResizableInput';

import { EAvailableCurrency } from '@shared/constants/siteInfo';

import { CurrencyInputFormRegister } from './type';

interface IProps extends CurrencyInputFormRegister {
  currency: string;
  className?: string;
  inputClassName?: string;
  errorMessage?: string;
  isFormInput?: boolean;
  value?: string | number;
  placeholder?: string;
  minWidth?: number;
}

const SingleCurrencyInput = ({
  currency,
  className,
  inputClassName,
  errorMessage,
  register,
  isFormInput,
  value,
  placeholder,
  minWidth,
}: IProps) => {
  const { setValue } = useFormContext();

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={className} ref={containerRef}>
      {isFormInput ? (
        <HookFormInput
          id={currency}
          register={register(currency as EAvailableCurrency.AITECH)}
          error={errorMessage}
          required
          innerContainerClassName="w-full flex"
          inputClassName={inputClassName}
          placeholder={placeholder}
          minWidth={minWidth}
          maxLength={20}
          onChange={(e) => {
            const sanitized = sanitizeInput(e.target.value);

            setValue(currency, sanitized, {
              shouldValidate: true,
            });
          }}
        >
          <CurrencyLabel currency={currency} />
        </HookFormInput>
      ) : (
        <div className="flex w-full flex-row items-center justify-between border border-neutral700 bg-neutral800 px-2.5 py-3">
          <AutoResizableInput
            id={currency}
            value={value}
            containerRef={containerRef}
            readonly
            containerClassName="w-full flex"
            inputClassName={inputClassName}
          >
            <CurrencyLabel currency={currency} />
          </AutoResizableInput>
        </div>
      )}
    </div>
  );
};

export default SingleCurrencyInput;
